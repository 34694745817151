<template>
  <div class="admin_page">
    <div class="container">

    <div id="admin_navigation">

    </div>
    </div>
  </div>
</template>
<script>
export default {
    components: {
    }
}
</script>